import { Dispatch } from 'redux'

import AuthApi from './api'
import {
  AUTH_ACTIONS,
  IAuthState,
  AuthActions,
  IProfileUpdate,
  LoadUserAction,
  LoadUserProfileAction,
  AuthCredentials,
  LoginCredentials,
  ProfileCreateData
} from './types'
import { RootState } from 'store/types'
import { throwErrorWithMessage } from 'api/apiMessages'
import InvitationApi from 'api/invitationApi'
import socketApi from 'utils/socketApi'

export const AuthInitialState: IAuthState = {
  user: undefined
}

export const authReducer = (state: IAuthState = AuthInitialState, action: AuthActions) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOAD_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        }
      }
    case AUTH_ACTIONS.LOAD_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user!,
          profile: {
            ...state.user?.profile,
            ...action.profile,
            avatar: action.profile.avatar
          }
        }
      }
    default:
      return state
  }
}

// Action creators:

export const getUser = () => async (dispatch: Dispatch<LoadUserAction>) => {
  try {
    const { data } = await AuthApi.getUserData()
    socketApi.connect()
    dispatch({ type: AUTH_ACTIONS.LOAD_USER, user: data })
  } catch (error) {
    socketApi.disconnect()
    throw new Error(error)
  }
}

export const login = (credentials: LoginCredentials) => async (dispatch: Dispatch<LoadUserAction>) => {
  try {
    const { data } = await AuthApi.login({ username: credentials.username, password: credentials.password })
    const domain = process.env.REACT_APP_DOMAIN ? ` Domain=${process.env.REACT_APP_DOMAIN}` : ''
    const expires = `expires=${credentials.keepSignedIn ? 'Fri, 31 Dec 9999 23:59:59 GMT' : '0'}`
    document.cookie = `token=${data.token}; ${expires}; path=/; ${domain}`
    socketApi.connect()
    dispatch({ type: AUTH_ACTIONS.LOAD_USER, user: data.user })
  } catch (e) {
    throwErrorWithMessage(e)
  }
}

export const register = (credentials: AuthCredentials) => async (dispatch: Dispatch<LoadUserAction>) => {
  try {
    const invitationId = InvitationApi.getInvitationId() || undefined
    const { data } = await AuthApi.register({ ...credentials, invitationId })
    const domain = process.env.REACT_APP_DOMAIN ? ` Domain=${process.env.REACT_APP_DOMAIN}` : ''
    document.cookie = `token=${data.token};${domain}`
    socketApi.connect()
    dispatch({ type: AUTH_ACTIONS.LOAD_USER, user: data.user })
  } catch (e) {
    throwErrorWithMessage(e)
  }
}

export const createProfile = (profileData: ProfileCreateData) => async (dispatch: Dispatch<LoadUserProfileAction>) => {
  try {
    const { data } = await AuthApi.createUserProfile(profileData)
    dispatch({ type: AUTH_ACTIONS.LOAD_USER_PROFILE, profile: data.profile })
  } catch (error) {
    console.warn(error)
  }
}

export const changeEmail = (email: string) => async (dispatch: Dispatch<LoadUserProfileAction>) => {
  try {
    const { data } = await AuthApi.changeEmail(email)
    dispatch({ type: AUTH_ACTIONS.LOAD_USER_PROFILE, profile: data.profile })
  } catch (error) {
    throw new Error(error)
  }
}

export const updateUser = (profileData: IProfileUpdate) => async (dispatch: Dispatch<LoadUserProfileAction>) => {
  try {
    const { data } = await AuthApi.updateUserProfile(profileData)
    dispatch({ type: AUTH_ACTIONS.LOAD_USER_PROFILE, profile: data.profile })
  } catch (error) {
    console.warn(error)
  }
}

export const uploadAvatar = (avatar: FormData) => async (dispatch: Dispatch<LoadUserProfileAction>) => {
  try {
    const { data } = await AuthApi.updateUserProfile(avatar)
    dispatch({ type: AUTH_ACTIONS.LOAD_USER_PROFILE, profile: data.profile })
  } catch (error) {
    console.warn(error)
  }
}

export const updateGlobalScore = (scoreToAdd: number) => async (
  dispatch: Dispatch<LoadUserProfileAction>,
  getState: () => RootState
) => {
  try {
    const score = getState().auth.user?.profile.globalScore
    const { data } = await AuthApi.updateUserProfile({
      globalScore: score ? score + scoreToAdd : scoreToAdd
    })

    dispatch({ type: AUTH_ACTIONS.LOAD_USER_PROFILE, profile: data.profile })
  } catch (error) {
    console.warn(error)
  }
}

export const setOnboardingCompleted = () => async (dispatch: Dispatch<LoadUserAction>) => {
  try {
    const { data } = await AuthApi.setOnboardingCompleted()
    dispatch({ type: AUTH_ACTIONS.LOAD_USER, user: data })
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(error)
    }
  }
}
// Selectors:

export const selectUser = (state: RootState) => state.auth.user

export const selectProfile = (state: RootState) => (state.auth.user ? state.auth.user.profile : undefined)

export const selectUserCountry = (state: RootState) => state.auth.user?.profile?.country

export const selectGlobalScore = (state: RootState) => {
  const globalScore = state.auth.user?.profile?.globalScore
  return globalScore ? globalScore : 0
}
