import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'

import { AstridLogoBlack, Button, Typography } from '@astrid/components'
import SadAbbe from 'resources/graphics/abbe_sad.png'
import Screenshot from 'resources/graphics/landingpage_visuals.png'
import { useQuery } from 'utils/hooks'

import { HttpErrors } from '@astrid/shared'
import { useParams } from 'react-router'
import AuthApi from 'store/services/Auth/api'
import styles from './RegretDeletion.module.scss'

type Status = 'loading' | 'success' | 'deleted' | 'error'

interface Translation {
  successTitle: string
  deletedTitle: string
  errorTitle: string
  successText: string
  deletedText: string
  errorText: string
  questions: string
  contact: string
  createNewAccount: string
}

const translations: { en: Translation; vi: Translation } = {
  en: {
    successTitle: 'Account recovered!',
    deletedTitle: 'Account is already deleted!',
    errorTitle: ' Something went wrong',
    successText: 'You have successfully withdrawn the request to delete your account.',
    deletedText: 'Your account and personal data is already removed from Astrid.',
    errorText: 'Please contact support@astrideducation.com.',
    questions: 'Questions?',
    contact: 'Contact:',
    createNewAccount: 'Create new account'
  },
  vi: {
    successTitle: 'Tài khoản đã được khôi phục!',
    deletedTitle: 'Tài khoản đã được xóa!',
    errorTitle: ' Có xảy ra trục trặc nhỏ',
    successText: 'êu cầu khôi phục tài khoản của bạn đã được hoàn thành.',
    deletedText: 'Tài khoản và các thông tin của bạn đã được xóa khỏi Astrid',
    errorText: 'Vui lòng liên hệ support@astrideducation.com.',
    questions: 'Bạn có câu hỏi?',
    contact: 'Liên hệ:',
    createNewAccount: 'Tạo tài khoản mới'
  }
}

const selectTranslation = (langCode: string) => {
  switch (langCode) {
    case 'vi':
      return translations.vi
    default:
      return translations.en
  }
}

const RegretDeletion = () => {
  const [status, setStatus] = useState<Status>('loading')
  const lang = useQuery().get('country')?.toLocaleLowerCase() === 'vn' ? 'vi' : 'en'
  const params = useParams<{ regretToken: string }>()
  const { regretToken: token } = params
  const trans = selectTranslation(lang)

  useEffect(() => {
    if (token) {
      ;(async () => {
        try {
          await AuthApi.regretDeleteAccount(token)
          setStatus('success')
        } catch (error) {
          const alreadyDeleted =
            error instanceof Error &&
            (error as AxiosError<any>)?.response?.data.error === HttpErrors.ACCOUNT_DELETETION_ALREADY_DONE
          if (alreadyDeleted) {
            setStatus('deleted')
          } else {
            setStatus('error')
          }
        }
      })()
    } else {
      setStatus('error')
    }
  }, [token])

  return (
    <div className={styles.container}>
      {status !== 'loading' && (
        <>
          <header className={styles.header}>
            <AstridLogoBlack />
          </header>
          <div className={styles.content}>
            {status === 'success' && (
              <>
                <img className={styles.screenshot} src={Screenshot} alt="Astrid mobile app screenshot" />
                <Typography variant="h2" className={styles.heading}>
                  {trans.successTitle}
                </Typography>
                <Typography variant="body" className={styles.successText}>
                  {trans.successText}
                </Typography>
              </>
            )}

            {status === 'deleted' && (
              <>
                <img className={styles.screenshot} src={Screenshot} alt="Astrid mobile app screenshot" />
                <Typography variant="h2" className={styles.heading}>
                  {trans.deletedTitle}
                </Typography>
                <Typography variant="body">{trans.deletedText}</Typography>
                <Button
                  className={styles.button}
                  onClick={() => (window.location.href = `https://read.withastrid.com/app?lang=${lang}`)}>
                  {trans.createNewAccount}
                </Button>
              </>
            )}

            {status === 'error' && (
              <>
                <img className={styles.abbe} src={SadAbbe} alt="Sad Abbe" />
                <Typography variant="h2" className={styles.heading}>
                  {trans.errorTitle}
                </Typography>
                <Typography variant="body">{trans.errorText}</Typography>
              </>
            )}
          </div>

          {(status === 'success' || status === 'deleted') && (
            <footer className={styles.footer}>
              <Typography variant="body" className={styles.footerText}>
                <span role="img" aria-label="fire icon">
                  🔥
                </span>
                {trans.questions}
              </Typography>
              <Typography variant="body" className={`${styles.contact} ${styles.footerText}`}>
                {trans.contact} <span className={styles.email}>support@astrideducation.com</span>
              </Typography>
            </footer>
          )}
        </>
      )}
    </div>
  )
}

export default RegretDeletion
