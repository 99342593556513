const messages: { [key in string]: string } = {
  USER_NOT_FOUND: 'User not found.',
  USER_EXISTS: 'Account with this email already exist. Please try to log in.',
  USER_WRONG_CREDENTIALS: 'Wrong email or password.',
  TEACHER_WRONG_CREDENTIALS: 'You have entered an invalid email or password.',
  DEFAULT: 'Something went wrong, try again later.'
}

export const throwErrorWithMessage = (error: any) => {
  throw new Error(error.response ? error.response.data.error : error.message)
}
export const printApiMessage = (code = messages.DEFAULT) => (code in messages ? messages[code] : messages.DEFAULT)
