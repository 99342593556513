import { combineReducers } from 'redux'

import { RootState } from './types'

import { authReducer, AuthInitialState } from './services/Auth/authReducer'
import { exerciseReducer, ExerciseInitialState } from './services/Exercise/exerciseReducer'
import { bookReducer, BookInitialState } from './services/Book/bookReducer'
import { bookProgressReducer, BookProgressInitialState } from './services/BookProgress/bookProgressReducer'

import { AuthActions } from './services/Auth/types'
import { ExerciseActions } from './services/Exercise/types'
import { BookActions } from './services/Book/types'
import { BookProgressActions } from './services/BookProgress/types'

export const combinedReducers = combineReducers({
  auth: authReducer,
  exercise: exerciseReducer,
  book: bookReducer,
  bookProgress: bookProgressReducer
})

export const initialRootState: RootState = {
  auth: AuthInitialState,
  exercise: ExerciseInitialState,
  book: BookInitialState,
  bookProgress: BookProgressInitialState
}

export const rootReducer = (
  state = initialRootState,
  action: AuthActions | ExerciseActions | BookActions | BookProgressActions
) => {
  return combinedReducers(state, action)
}
