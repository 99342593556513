import { AxiosPromise } from 'axios'

import api from '../../../api/api'
import { IUser, IProfileUpdate, AuthCredentials, AuthResponse, ProfileCreateData } from './types'

class AuthApi {
  static login = (data: AuthCredentials): AxiosPromise<AuthResponse> => api.post('/auth', data)
  static logout = () => {
    const domain = process.env.REACT_APP_DOMAIN ? ` Domain=${process.env.REACT_APP_DOMAIN}` : ''
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;${domain}`
    window.location.reload()
  }
  static register = (data: AuthCredentials & { invitationId?: string }): AxiosPromise<AuthResponse> =>
    api.post('/auth/signup', data)
  static getUserData = (): AxiosPromise<IUser> => api.get('/user')
  static createUserProfile = (data: ProfileCreateData): AxiosPromise<IUser> => api.post('/user/profile', data)
  static updateUserProfile = (updateData: IProfileUpdate | FormData): AxiosPromise<IUser> =>
    api.patch(`/user/profile`, updateData)
  static setOnboardingCompleted = (): AxiosPromise<IUser> => api.patch('/user', { onboardingCompleted: true })
  static sendRecoverPasswordEmail = (email: string) => api.get(`/auth/user/reset-pass/${email}`)
  static setNewPassword = (password: string, token: string) =>
    api.post('/auth/reset-pass', { password }, { headers: { authorization: token } })
  static changePassword = (password: string): AxiosPromise<IUser> => api.patch('/user', { password })
  static changeEmail = (email: string): AxiosPromise<IUser> => api.patch('/user', { email })
  static deleteAccount = () => api.delete('/user')
  static regretDeleteAccount = (regretToken: string): AxiosPromise<void> =>
    api.post('/account-deletion-request/regret', { regretToken })
}

export default AuthApi
