import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AuthWall from './AuthWall/AuthWall'
import AppcuesWrapper from 'components/Appcues/AppcuesWrapper'

export const routes = {
  home: '/',
  profile: '/profile',
  profile_edit: '/profile/edit',
  book: '/book',
  exercises: '/exercises',
  sentence_builder: 'sentence-builder',
  word_race: 'word-race',
  word_craft: 'word-craft',
  congratulation: '/congratulation',
  createProfile: '/create-profile',
  register: '/register',
  onboarding_finished: '/onboarding-finished',
  invitation: '/invitation',
  reset_password: '/auth/reset-pass/:resetToken',
  regret_deletion: '/regret-delete-account/:regretToken',
  app: '/app'
}

const RouterStackComponent = () => (
  <Router>
    <AppcuesWrapper>
      <AuthWall />
    </AppcuesWrapper>
  </Router>
)

export default RouterStackComponent
