import io from 'socket.io-client'
import { cookiesToObject } from 'utils'

export enum WSEventType {
  NEW_REWARD = 'WS_NEW_REWARD',
  /** Event occurs whenever new connection is established. */
  Connection = 'connection',
  /** Received when client disconnects from WS Server. */
  Disconnect = 'disconnect',
  /**
   * Event responsible for adding connected socket to the clients' map.
   * Only registered sockets will receive frames.
   * #### Type: Incoming
   *
   *    interface Payload {
   *      token: string;
   *    }
   */
  Register = 'WS_REGISTER',
  /**
   * Event used to join to a certain socket.io room.
   * Rooms receive messages related to auction system.
   * #### Type: Incoming
   *
   *    interface Payload {
   *      room: Types.ObjecID;
   *    }
   */
  Subscribe = 'WS_SUBSCRIBE',
  /**
   * Event used to leave to a certain socket.io room.
   * Rooms receive messages related to auction system.
   * #### Type: Incoming
   *
   *    interface Payload {
   *      room: Types.ObjecID;
   *    }
   */
  Unsubscribe = 'WS_UNSUBSCRIBE',
  /** Event issued if client is not registered or has incorrect token. */
  Error = 'WS_ERROR'
}

class SocketAPI {
  _socket: SocketIOClient.Socket | undefined
  constructor() {
    this._socket = io.connect(process.env.REACT_APP_SOCKET_URL as string, { transports: ['websocket'] })
    this.registerListeners()
  }

  connect = (): Promise<SocketIOClient.Socket> =>
    new Promise((resolve, reject) => {
      if (!this._socket) {
        this._socket = io.connect(process.env.REACT_APP_SOCKET_URL as string, {
          transports: ['websocket']
        })
        if (this._socket) {
          this.registerListeners()
          resolve(this._socket)
        } else {
          reject('Connection failure.')
        }
      } else {
        resolve(this._socket)
      }
    })

  on = (event: string, callback: Function) => {
    this._socket?.on(event, callback)
  }

  private registerListeners = () => {
    this._socket?.on('connect', () => {
      const cookiesObj = cookiesToObject()
      this.emit(WSEventType.Register, { token: cookiesObj.token })
      console.log('Socket connected.')
    })

    this._socket?.on('reconnect', () => {
      const cookiesObj = cookiesToObject()
      this.emit(WSEventType.Register, { token: cookiesObj.token })
      console.log('Socket reconnected.')
    })

    this._socket?.on('disconnect', (reason: string) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, need to reconnect manually
        this.connect()
      }
      // else the socket will automatically try to reconnect
    })

    this._socket?.on('connect_error', () => {
      console.warn('Socket connection failure.')
    })
  }

  disconnect = () => {
    this._socket?.disconnect()
    this._socket = undefined
  }

  emit = (event: string, payload?: any) => {
    this._socket?.emit(event, payload && payload)
  }

  remove = (event: string, callback: Function) => {
    this._socket?.removeListener(event, callback)
  }
}

export default new SocketAPI()
