import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MobileAppDownload from 'views/MobileAppDownload/MobileAppDownload'
import RegretDeletion from 'views/RegretDeletion/RegretDeletion'
import ResetPassword from 'views/ResetPassword/ResetPassword'
import { routes } from './RouterStackComponent'

const AuthRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.home} exact component={MobileAppDownload} />
      <Route path={routes.reset_password} exact component={ResetPassword} />
      <Route path={routes.app} exact component={MobileAppDownload} />
      <Route path={routes.regret_deletion} exact component={RegretDeletion} />
      <Redirect to={routes.home} />
    </Switch>
  )
}

export default AuthRouter
