import { AxiosPromise } from 'axios'

import api from 'api/api'
import { Paginated } from 'utils/types'
import { IBookProgress, IUpdateBookProgressData } from './types'
import { BookRate } from '@astrid/components'

class BookProgressApi {
  static getBookProgress = (): AxiosPromise<Paginated<IBookProgress>> => api.get('/book-progress?$limit=1000') // pagination not used for now
  static getBookProgressByID = (id: string): AxiosPromise<IBookProgress> => api.get(`/book-progress/${id}`)
  static createBookProgress = (bookId: string): AxiosPromise<IBookProgress> =>
    api.post('/book-progress', {
      bookId,
      isCompleted: false,
      index: 0
    })
  static updateBookProgress = (progressId: string, data: IUpdateBookProgressData): AxiosPromise<IBookProgress> =>
    api.patch(`/book-progress/${progressId}`, data)
  static rateBook = (progressId: string, rate: BookRate): AxiosPromise<IBookProgress> =>
    api.patch(`/book-progress/${progressId}/rate`, { rating: rate })
}

export default BookProgressApi
