"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpErrors = void 0;
var HttpErrors;
(function (HttpErrors) {
    HttpErrors["ACCOUNT_DELETETION_ALREADY_DONE"] = "ACCOUNT_DELETETION_ALREADY_DONE";
    HttpErrors["ACCOUNT_DELETETION_ALREADY_REGRETTED"] = "ACCOUNT_DELETETION_ALREADY_REGRETTED";
    HttpErrors["ACCOUNT_DELETETION_UNKNOWN_TOKEN"] = "ACCOUNT_DELETETION_UNKNOWN_TOKEN";
    HttpErrors["AccountDisabled"] = "ACCOUNT_DISABLED";
    HttpErrors["AdminWrongCredentials"] = "ADMIN_WRONG_CREDENTIALS";
    HttpErrors["BookNotFound"] = "BOOK_NOT_FOUND";
    HttpErrors["BookProgressNotFound"] = "BOOK_PROGRESS_NOT_FOUND";
    HttpErrors["BookPublished"] = "BOOK_PUBLISHED";
    HttpErrors["ExerciseNotFound"] = "EXERCISE_NOT_FOUND";
    HttpErrors["FileNotUploaded"] = "FILE_NOT_UPLOADED";
    HttpErrors["FileSizeExceeded"] = "MAX_FILE_SIZE_EXCEEDED";
    HttpErrors["FirebaseAuthError"] = "FIREBASE_AUTH_ERROR";
    HttpErrors["ImageNotUploaded"] = "IMAGE_NOT_UPLOADED";
    HttpErrors["MaxNumberOfStudentsExceeded"] = "MAXIMUM_NUMBER_OF_STUDENTS_IN_CLASS_EXCEEDED";
    HttpErrors["NoResourceForClient"] = "NO_RESOURCE_FOR_CLIENT";
    HttpErrors["OtpInvalidPhoneNumber"] = "OTP_INVALID_PHONE_NUMBER";
    HttpErrors["OtpVerificationBadCode"] = "OTP_VERIFICATION_BAD_CODE";
    HttpErrors["OtpVerificationCanceled"] = "OTP_VERIFICATION_CANCELED";
    HttpErrors["OtpVerificationNotFound"] = "OTP_VERIFICATION_NOT_FOUND";
    HttpErrors["ProfileAlreadyExists"] = "PROFILE_ALREADY_EXISTS";
    HttpErrors["ProfileNotFound"] = "PROFILE_NOT_FOUND";
    HttpErrors["SSONoEmail"] = "SSO_NO_EMAIL_ERROR";
    HttpErrors["StatusCodeNeverSet"] = "STATUS_CODE_NEVER_SET";
    HttpErrors["SuspiciousBehaviour"] = "SUSPICIOUS_BEHAVIOUR";
    HttpErrors["TeacherExists"] = "TEACHER_EXISTS";
    HttpErrors["TeacherNoPassword"] = "TEACHER_DID_NOT_SET_A_PASSWORD";
    HttpErrors["TeacherWrongCredentials"] = "TEACHER_WRONG_CREDENTIALS";
    HttpErrors["TutorExists"] = "TUTOR_EXISTS";
    HttpErrors["TutorWrongCredentials"] = "TUTOR_WRONG_CREDENTIALS";
    HttpErrors["UnknownVideo"] = "UNKNWON_VIDEO";
    HttpErrors["UpstreamError"] = "UPSTREAM_ERROR";
    HttpErrors["UserExists"] = "USER_EXISTS";
    HttpErrors["UserNoPassword"] = "USER_DID_NOT_SET_A_PASSWORD";
    HttpErrors["UserNotFound"] = "USER_NOT_FOUND";
    HttpErrors["UserWrongCredentials"] = "USER_WRONG_CREDENTIALS";
})(HttpErrors = exports.HttpErrors || (exports.HttpErrors = {}));
