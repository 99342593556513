import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import SocketAPI, { WSEventType } from 'utils/socketApi'
import { IReward } from 'store/services/Auth/types'
import AchievementIcon from './AchievementIcon'
import { Typography, InjectedSoundProps, withSoundEffectsContext } from '@astrid/components'
import { getUser } from 'store/services/Auth/authReducer'

import './AchievementHandler.scss'

const AchievementHandler: React.FC<InjectedSoundProps> = ({ playSound }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [achievementsQueue, setAchievementsQueue] = useState<IReward[]>([])
  const latestReward = achievementsQueue[0]
  const dispatch = useDispatch()

  const removeFirstElementFromQueue = useCallback(() => {
    setShowPopup(false)
    setTimeout(() => {
      setAchievementsQueue((queue) => {
        if (queue.length >= 2) {
          playSound('achievement', () => setTimeout(removeFirstElementFromQueue, 1500))
          dispatch(getUser())
          setShowPopup(true)
        }
        return queue.slice(1)
      })
    }, 300)
  }, [playSound, dispatch])

  useEffect(() => {
    SocketAPI.on(WSEventType.NEW_REWARD, (reward: IReward) => {
      setAchievementsQueue((queue) => {
        if (queue.length === 0) {
          playSound('achievement', () => setTimeout(removeFirstElementFromQueue, 1500))
          dispatch(getUser())
          setShowPopup(true)
        }
        return [...queue, reward]
      })
    })
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`achievement-popup${showPopup ? '--open' : ''}`}>
      <AchievementIcon reward={latestReward} />
      <div>
        <Typography variant="h4">Achievement Unlocked!</Typography>
        <Typography variant="exerciseS">{latestReward?.name}</Typography>
      </div>
    </div>
  )
}

export default withSoundEffectsContext(AchievementHandler)
