import React from 'react'
import ReactDOM from 'react-dom'
import { isAndroid, isIOS } from 'react-device-detect'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { GA_init, initHotjar } from '@astrid/components'

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
}

GA_init(process.env.REACT_APP_GA_ID)

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_HOTJAR_ID &&
  typeof process.env.REACT_APP_HOTJAR_ID === 'string'
) {
  initHotjar(process.env.REACT_APP_HOTJAR_ID)
}

// Redirect to store for supported platforms
if (isIOS) {
  window.location.href = 'https://apps.apple.com/us/app/astrid-learn-to-speak-english/id1528670445'
} else if (isAndroid) {
  window.location.href = 'https://play.google.com/store/apps/details?id=com.teach.withastrid'
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
