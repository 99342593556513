import React, { useCallback, useContext, useState } from 'react'
import * as Yup from 'yup'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import AuthWrapper from 'components/AuthWrapper/AuthWrapper'
import { ValidationRules } from 'utils/ValidationRules'
import { Formik, FormikHelpers } from 'formik'
import { TextInput, Button, Typography, AlertContext } from '@astrid/components'
import { routes } from 'routes/RouterStackComponent'
import { printApiMessage } from 'api/apiMessages'
import AuthApi from 'store/services/Auth/api'
import './ResetPassword.scss'

const validationSchema = Yup.object().shape({
  password: ValidationRules.password,
  password2: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required')
})

interface FormFields {
  password: string
  password2: string
}

const ResetPassword: React.FC<RouteComponentProps<{ resetToken: string }>> = ({ match, history }) => {
  const { resetToken } = match.params
  const [success, setSuccess] = useState(false)
  const { showAlert } = useContext(AlertContext)
  if (!resetToken || resetToken === '') {
    history.push(routes.home)
  }

  const handleSubmit = useCallback(
    async ({ password }: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      try {
        await AuthApi.setNewPassword(password, resetToken)
        setSuccess(true)
      } catch ({ message }) {
        setSubmitting(false)
        showAlert(printApiMessage((message as string) || 'Change password error'))
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <AuthWrapper>
      {success ? (
        <>
          <Typography variant="h3">The password has been changed.</Typography>
          <a href="https://read.withastrid.com/" target="__blank" className="mobile-app-button">
            <Typography variant="button">Open Astrid App</Typography>
          </a>
        </>
      ) : (
        <>
          <Typography variant="h3">Set new password for your account</Typography>
          <Formik
            initialValues={{ password: '', password2: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    name="password"
                    label="New password"
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    error={touched.password && !!errors.password}
                    helperText={(touched.password && errors.password) || ''}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <TextInput
                    name="password2"
                    label="Confirm new password"
                    type="password"
                    value={values.password2}
                    onChange={handleChange('password2')}
                    error={touched.password2 && !!errors.password2}
                    helperText={(touched.password2 && errors.password2) || ''}
                    onBlur={() => setFieldTouched('password2')}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Set password
                  </Button>
                </form>
              )
            }}
          </Formik>
        </>
      )}
    </AuthWrapper>
  )
}

export default withRouter(ResetPassword)
