import { AxiosPromise } from 'axios'

import api from 'api/api'
import { IExercise, ExerciseType } from '@astrid/components'

class ExerciseApi {
  static getLevelUpExercises = (type: ExerciseType): AxiosPromise<IExercise[]> =>
    api.get(`/exercise`, {
      params: {
        $filter: `type:${type}`,
        $limit: type === ExerciseType.ReadWordRace ? 1 : 5
      }
    })
  static getExerciseById = (id: string): AxiosPromise<IExercise> => api.get(`/exersice/${id}`)
  static getBookExercises = (bookId: string): AxiosPromise<IExercise[]> => api.get(`/book/${bookId}/exercises`)
}

export default ExerciseApi
