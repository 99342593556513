import React from 'react'

import { AchievementName, IReward } from 'store/services/Auth/types'

import { ReactComponent as GoalsIcon } from 'resources/graphics/achievements-icons/goals.svg'
import { ReactComponent as Goals200Icon } from 'resources/graphics/achievements-icons/goals-200.svg'
import { ReactComponent as Goals300Icon } from 'resources/graphics/achievements-icons/goals-300.svg'
import { ReactComponent as Goals400Icon } from 'resources/graphics/achievements-icons/goals-400.svg'
import { ReactComponent as NewRecordIcon } from 'resources/graphics/achievements-icons/new-record.svg'
import { ReactComponent as PageMasterIcon } from 'resources/graphics/achievements-icons/page-master.svg'
import { ReactComponent as PageMasterBronzeIcon } from 'resources/graphics/achievements-icons/page-master-bronze.svg'
import { ReactComponent as PageMasterSilverIcon } from 'resources/graphics/achievements-icons/page-master-silver.svg'
import { ReactComponent as PageMasterGoldIcon } from 'resources/graphics/achievements-icons/page-master-gold.svg'
import { ReactComponent as PerfectMonthIcon } from 'resources/graphics/achievements-icons/perfect-month.svg'
import { ReactComponent as PerfectWeekIcon } from 'resources/graphics/achievements-icons/perfect-week.svg'
import { ReactComponent as PowerBuilderIcon } from 'resources/graphics/achievements-icons/power-builder.svg'
import { ReactComponent as PowerBuilderBronzeIcon } from 'resources/graphics/achievements-icons/power-builder-bronze.svg'
import { ReactComponent as PowerBuilderSilverIcon } from 'resources/graphics/achievements-icons/power-builder-silver.svg'
import { ReactComponent as PowerBuilderGoldIcon } from 'resources/graphics/achievements-icons/power-builder-gold.svg'
import { ReactComponent as SpeedMasterIcon } from 'resources/graphics/achievements-icons/speed-master.svg'
import { ReactComponent as SpeedMasterBronzeIcon } from 'resources/graphics/achievements-icons/speed-master-bronze.svg'
import { ReactComponent as SpeedMasterSilverIcon } from 'resources/graphics/achievements-icons/speed-master-silver.svg'
import { ReactComponent as SpeedMasterGoldIcon } from 'resources/graphics/achievements-icons/speed-master-gold.svg'
import { ReactComponent as VipIcon } from 'resources/graphics/achievements-icons/vip.svg'
import { ReactComponent as WordWizardIcon } from 'resources/graphics/achievements-icons/word-wizard.svg'
import { ReactComponent as WordWizardBronzeIcon } from 'resources/graphics/achievements-icons/word-wizard-bronze.svg'
import { ReactComponent as WordWizardSilverIcon } from 'resources/graphics/achievements-icons/word-wizard-silver.svg'
import { ReactComponent as WordWizardGoldIcon } from 'resources/graphics/achievements-icons/word-wizard-gold.svg'

interface IProps {
  reward: IReward
}

const medalAwarder = (
  score: number,
  normal: JSX.Element,
  bronze: JSX.Element,
  silver: JSX.Element,
  gold: JSX.Element
) => {
  if (score > 0 && score <= 50) {
    return normal
  } else if (score > 50 && score <= 100) {
    return bronze
  } else if (score > 100 && score <= 200) {
    return silver
  } else if (score > 200) {
    return gold
  } else {
    return normal
  }
}

const AchievementIcon: React.FC<IProps> = ({ reward }) => {
  if (!reward) return null
  switch (reward.name) {
    case AchievementName.GOALS:
      return <GoalsIcon />
    case AchievementName.GOALS_200:
      return <Goals200Icon />
    case AchievementName.GOALS_300:
      return <Goals300Icon />
    case AchievementName.GOALS_400:
      return <Goals400Icon />
    case AchievementName.NEW_RECORD:
      return <NewRecordIcon />
    case AchievementName.PAGEMASTER:
      return medalAwarder(
        reward.score!,
        <PageMasterIcon />,
        <PageMasterBronzeIcon />,
        <PageMasterSilverIcon />,
        <PageMasterGoldIcon />
      )
    case AchievementName.WORD_WIZARD:
      return medalAwarder(
        reward.score!,
        <WordWizardIcon />,
        <WordWizardBronzeIcon />,
        <WordWizardSilverIcon />,
        <WordWizardGoldIcon />
      )
    case AchievementName.SPEEDMASTER:
      return medalAwarder(
        reward.score!,
        <SpeedMasterIcon />,
        <SpeedMasterBronzeIcon />,
        <SpeedMasterSilverIcon />,
        <SpeedMasterGoldIcon />
      )
    case AchievementName.POWER_BUILDER:
      return medalAwarder(
        reward.score!,
        <PowerBuilderIcon />,
        <PowerBuilderBronzeIcon />,
        <PowerBuilderSilverIcon />,
        <PowerBuilderGoldIcon />
      )
    case AchievementName.PERFECT_MONTH:
      return <PerfectMonthIcon />
    case AchievementName.PERFECT_WEEK:
      return <PerfectWeekIcon />
    case AchievementName.VIP:
      return <VipIcon />
    default:
      return null
  }
}

export default AchievementIcon
