import { Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { RootState } from 'store/types'
import { IBookState, BOOK_ACTIONS, BookActions, AddAllBooksAction } from './types'
import BookApi from './api'
import { sortBooksByCEFR } from '@astrid/components'

export const BookInitialState: IBookState = {
  books: []
}

export const bookReducer = (state: IBookState = BookInitialState, action: BookActions) => {
  switch (action.type) {
    case BOOK_ACTIONS.ADD_ALL_BOOKS:
      return {
        ...state,
        books: [...action.books]
      }
    default:
      return state
  }
}

// Action creators:

export const getAllBooks = () => async (dispatch: Dispatch<AddAllBooksAction>) => {
  try {
    const { data } = await BookApi.getAllBooks()
    dispatch({ type: BOOK_ACTIONS.ADD_ALL_BOOKS, books: sortBooksByCEFR(data.results) })
  } catch (error) {
    console.warn(error)
  }
}

// Selectors:

export const selectAllBooks = (state: RootState) => state.book.books

export const selectBookById = createSelector(
  selectAllBooks,
  (_: any, id?: string) => id,
  (books, id) => books.find((book) => book._id === id)
)
