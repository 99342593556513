import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/services/Auth/authReducer'

const AppcuesWrapper: React.FC<RouteComponentProps> = ({ children, location }) => {
  const user = useSelector(selectUser)
  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page()
      window.Appcues.identify(user ? user._id : `${Date.now()}`)
    }
  }, [location.pathname, user])

  return <>{children}</>
}

export default withRouter(AppcuesWrapper)
