import API from './api'
import { AxiosPromise } from 'axios'

export default class InvitationApi {
  static storeInvitationId = (id: string) => localStorage.setItem('INVITATION_ID', id)
  static getInvitationId = () => localStorage.getItem('INVITATION_ID')
  static removeInvitationId = () => localStorage.removeItem('INVITATION_ID')
  static acceptInvitation = (id: string): AxiosPromise<DetailedInvitationInfo> => API.post(`/invitation/${id}`)
  static getInvitationInfo = (id: string): AxiosPromise<InvitationInfo> => API.get(`/invitation/${id}`)
}

interface InvitationInfo {
  _id: string
  country: string
  school: string
  grade: string
  name: string
}

export interface DetailedInvitationInfo {
  className: string
  grade: number
  teacher: {
    _id: string
    profile: {
      firstName: string
      lastName: string
      school: string
      country: string
      city: string
      avatar: string
    }
  }
}
