import { Dispatch } from 'redux'
import { createSelector } from 'reselect'

import BookProgessApi from './api'
import { RootState } from 'store/types'
import {
  IBookProgressState,
  BOOK_PROGRESS_ACTIONS,
  BookProgressActions,
  AddBookProgressAction,
  UpdateOneBookProgressAction,
  IUpdateBookProgressData
} from './types'
import { BookRate } from '@astrid/components'

export const BookProgressInitialState: IBookProgressState = {
  progress: []
}

export const bookProgressReducer = (
  state: IBookProgressState = BookProgressInitialState,
  action: BookProgressActions
) => {
  switch (action.type) {
    case BOOK_PROGRESS_ACTIONS.ADD_BOOK_PROGRESS:
      return {
        progress: [...state.progress, ...action.progress]
      }
    case BOOK_PROGRESS_ACTIONS.UPDATE_ONE_BOOK_PROGRESS:
      const { progress } = action
      const updatedProgress = state.progress.filter((prog) => prog._id !== progress._id)
      updatedProgress.push(progress)

      return {
        progress: [...updatedProgress]
      }
    default:
      return state
  }
}

export const getAllBooksProgress = () => async (dispatch: Dispatch<AddBookProgressAction>) => {
  try {
    const { data } = await BookProgessApi.getBookProgress()
    dispatch({ type: BOOK_PROGRESS_ACTIONS.ADD_BOOK_PROGRESS, progress: data.results })
  } catch (error) {
    console.warn(error)
  }
}

export const createNewBookProgess = (bookId: string) => async (dispatch: Dispatch<AddBookProgressAction>) => {
  try {
    const { data } = await BookProgessApi.createBookProgress(bookId)
    dispatch({ type: BOOK_PROGRESS_ACTIONS.ADD_BOOK_PROGRESS, progress: [data] })
  } catch (error) {
    console.warn(error)
  }
}

export const updateOneBookProgress = (progressId: string, updateData: IUpdateBookProgressData) => async (
  dispatch: Dispatch<UpdateOneBookProgressAction>
) => {
  try {
    const { data } = await BookProgessApi.updateBookProgress(progressId, updateData)
    dispatch({
      type: BOOK_PROGRESS_ACTIONS.UPDATE_ONE_BOOK_PROGRESS,
      progress: data
    })
  } catch (error) {
    console.warn(error)
  }
}

export const rateBook = (progressId: string, rate: BookRate) => async (
  dispatch: Dispatch<UpdateOneBookProgressAction>
) => {
  try {
    const { data } = await BookProgessApi.rateBook(progressId, rate)
    dispatch({
      type: BOOK_PROGRESS_ACTIONS.UPDATE_ONE_BOOK_PROGRESS,
      progress: data
    })
  } catch (error) {
    console.warn(error)
  }
}

// Selectors:

export const selectAllBookProgress = (state: RootState) => state.bookProgress.progress

export const selectLatestProgress = createSelector(selectAllBookProgress, (progress) => {
  const withoutFinished = progress.filter((prog) => !prog.isCompleted)
  if (withoutFinished.length > 0) {
    return withoutFinished.reduce((prev, current) => {
      return prev.updatedAt > current.updatedAt ? prev : current
    }, withoutFinished[0])
  } else return
})

export const selectBookProgressById = createSelector(
  selectAllBookProgress,
  (_: any, id?: string) => id,
  (progress, id) => progress.find((prog) => prog.bookId === id)
)
