import { BookRate } from '@astrid/components'

export interface IBookProgressState {
  progress: IBookProgress[]
}

export enum BOOK_PROGRESS_ACTIONS {
  ADD_BOOK_PROGRESS = 'BOOK_PROGRESS/add_book_progress',
  UPDATE_ONE_BOOK_PROGRESS = 'BOOK_PROGRESS/update_one_book_progress'
}

// Typings:

export interface IBookProgress {
  _id: string
  bookId: string
  index: number
  indexListening: number
  isCompletedListening: boolean
  isCompleted: boolean
  updatedAt: number
  difficultWords: {
    word: string
    score: number
  }[]
  rating?: BookRate
}

export interface ICreateBookProgressData {
  index: number
  bookId: string
  isCompleted: boolean
}

export interface IUpdateBookProgressData {
  index?: number
  indexListening?: number
  isCompleted?: boolean
  isCompletedListening?: boolean
}

// Actions:

export interface AddBookProgressAction {
  type: BOOK_PROGRESS_ACTIONS.ADD_BOOK_PROGRESS
  progress: IBookProgress[]
}

export interface UpdateOneBookProgressAction {
  type: BOOK_PROGRESS_ACTIONS.UPDATE_ONE_BOOK_PROGRESS
  progress: IBookProgress
}

export type BookProgressActions = AddBookProgressAction | UpdateOneBookProgressAction
