import * as Yup from 'yup'

export const ValidationRules = {
  required: Yup.string().required('Required'),
  requiredNum: Yup.number().required('Required'),
  email: Yup.string().email('Email format is not valid.').required('Required'),
  emailNotRequired: Yup.string().email('Email format is not valid.'),
  password: Yup.string()
    .required('Required')
    .trim()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
      'Required at least 6 characters including number, upper & lower case.'
    )
}
