import React, { useEffect } from 'react'

import api from 'api/api'
import { AstridLogoWhite, Typography } from '@astrid/components'

import './EmailConfirmed.scss'

const EmailConfirmed: React.FC<{ token: string }> = ({ token }) => {
  useEffect(() => {
    ;(async () => {
      try {
        await api.get(`/auth/verify/${token}`)
      } catch (error) {}
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="email-confirmed">
      <AstridLogoWhite className="email-confirmed__logo" />
      <Typography variant="h1" style={{ color: '#fff', alignSelf: 'center', textAlign: 'center' }}>
        Email confirmed!
      </Typography>
      <a className="email-confirmed__button" href="https://astrideducation.onelink.me/G6CQ/47327c15">
        Go to Astrid
      </a>
    </div>
  )
}

export default EmailConfirmed
