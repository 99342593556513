import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import AuthRouter from 'routes/AuthRouter'
import EmailConfirmed from 'views/EmailConfirmed/EmailConfirmed'

const AuthWall = () => {
  // const matchInvitation = useRouteMatch<{ id: string }>({
  //   path: '/invitation/:id',
  //   strict: true,
  //   exact: true
  // })

  const matchEmail = useRouteMatch<{ token: string }>({
    path: '/auth/verify/:token',
    strict: true,
    exact: true
  })

  // const matchEmailChange = useRouteMatch<{ token: string }>({
  //   path: '/auth/verify-change/:token',
  //   strict: true,
  //   exact: true
  // })

  useEffect(() => {
    // @ts-ignore
    const isChrome = !!window.chrome && (!!window.chrome?.webstore || !!window.chrome?.runtime)
    const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

    // if (matchInvitation) {
    //   InvitationApi.storeInvitationId(matchInvitation.params.id)
    // }

    if (isChrome && isIOS) {
      alert('The app will not work properly with Chrome on iOS. Please use Safari browser when on iOS device.')
    }
  }, [])

  if (matchEmail) {
    return <EmailConfirmed token={matchEmail.params.token} />
  } else {
    return <AuthRouter />
  }
}

export default AuthWall
