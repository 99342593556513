/* Configurable: */
export enum CONFIG {
  DEFAULT_SCORE = 0,
  MAX_LEVEL = 99,
  SCORE_PER_LEVEL = 1000,
  AUTO_RECORD_COUNTDOWN = 3, // in seconds
  TIME_FOR_ONE_WORD = 2000,
  MIN_SUCCESS_SCORE = 50,
  HIGH_SUCCESS_SCORE = 75,
  NEXT_EXERCISE_DELAY = 1000,
  BLANK_WORD_SYMBOL = '#', // Sana API symbol that shows the empty phoneme
  DEFAULT_LANG = 'en-US',
  AMOUNT_OF_STARS_FLYING = 5
}

export enum Lang {
  US = 'en-US',
  British = 'en-GB'
}

export default CONFIG
