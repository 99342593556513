import { IExercise } from '@astrid/components'

export interface IExerciseState {
  readonly exercises: IExercise[]
  readonly activeExerciseIndex: number
  readonly currentBookExercisesAmount: number
}

export enum EXERCISE_ACTIONS {
  LOAD_EXERCISES = 'EXERCISE/load_exercises',
  CLEAN_STATE = 'EXERCISE/clean_state',
  SET_ACTIVE_EXERCISE_INDEX = 'EXERCISE/set_active_exercise_index',
  SET_CURRENT_BOOK_EXERCISES_AMOUNT = 'EXERCISE/set_current_book_exercises_amount'
}

// Actions:

export interface LoadExercisesAction {
  type: EXERCISE_ACTIONS.LOAD_EXERCISES
  exercises: IExercise[]
}

export interface CleanExerciseStateAction {
  type: EXERCISE_ACTIONS.CLEAN_STATE
}

export interface SetActiveExerciseAction {
  type: EXERCISE_ACTIONS.SET_ACTIVE_EXERCISE_INDEX
  activeIndex: number
}

export interface SetCurrentBookExercisesAmountAction {
  type: EXERCISE_ACTIONS.SET_CURRENT_BOOK_EXERCISES_AMOUNT
  amount: number
}

export type ExerciseActions =
  | LoadExercisesAction
  | SetActiveExerciseAction
  | SetCurrentBookExercisesAmountAction
  | CleanExerciseStateAction
