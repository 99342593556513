import { Book } from '@astrid/components'

export interface IBookState {
  readonly books: Book[]
}

export enum BOOK_ACTIONS {
  ADD_ALL_BOOKS = 'BOOK/add_all_books',
  GET_UNREAD_BOOKS = 'BOOK/get_unread_books',
  COMPLETE_BOOK = 'BOOK/complete_book',
  UNCOMPLETE_BOOK = 'BOOK/uncomplete_book'
}

// Actions:

export interface AddAllBooksAction {
  type: BOOK_ACTIONS.ADD_ALL_BOOKS
  books: Book[]
}

export type BookActions = AddAllBooksAction
