import { Lang, CONFIG } from 'config'

export const cookiesToObject = (): any =>
  document.cookie.split('; ').reduce((prev, current) => {
    const [name, value] = current.split('=')
    prev[name] = value
    return prev
  }, {})

export const shuffleArray = <T>(array: T[]): T[] => {
  const arrayClone = [...array]
  for (let i = arrayClone.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arrayClone[i]
    arrayClone[i] = arrayClone[j]
    arrayClone[j] = temp
  }
  return arrayClone
}

export const getRandomItemFromArray = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)]

export const getLanguage = (): string => {
  const lang: string | null = window.localStorage.getItem('lang')
  if (lang === null) {
    window.localStorage.setItem('lang', CONFIG.DEFAULT_LANG)
  }
  return lang || CONFIG.DEFAULT_LANG
}

export const toggleLanguage = (): void => {
  const currentLang: string = getLanguage()
  const newLang: string = currentLang === CONFIG.DEFAULT_LANG ? Lang.British : CONFIG.DEFAULT_LANG
  window.localStorage.setItem('lang', newLang)
  window.location.reload()
}

export const onRightOrLeftKeyboardArrows = (
  onRightCallback: () => void,
  onLeftCallback: () => void
): ((event: KeyboardEvent) => void) => {
  const onEventFired = (event: KeyboardEvent): void => {
    if (event.code === 'ArrowRight' || event.keyCode === 39) {
      onRightCallback()
    } else if (event.code === 'ArrowLeft' || event.keyCode === 37) {
      onLeftCallback()
    }
  }
  document.addEventListener('keyup', onEventFired)
  return onEventFired // return event function to be able to cleanup it in onWillUnmounted hook if need
}

export const getUserLevel = (userGlobalScore?: number): number => {
  if (userGlobalScore) {
    return Math.floor((userGlobalScore + CONFIG.DEFAULT_SCORE) / CONFIG.SCORE_PER_LEVEL)
  } else {
    return 0
  }
}

export const getUserLevelPercent = (userGlobalScore?: number): number => {
  if (userGlobalScore) {
    const pointsOnCurrentLevel = userGlobalScore % CONFIG.SCORE_PER_LEVEL
    return Math.floor((pointsOnCurrentLevel / CONFIG.SCORE_PER_LEVEL) * 100)
  } else {
    return 0
  }
}
