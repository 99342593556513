import { ExerciseType, StudentProfile } from '@astrid/components'

export interface IAuthState {
  readonly user: IUser | undefined
}

export enum AUTH_ACTIONS {
  LOAD_USER = 'AUTH/load_user',
  LOAD_USER_PROFILE = 'AUTH/load_user_profile'
}

// Typings:

export enum VerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  CHANGED = 'CHANGED',
  NO_EMAIL_USER = 'NO_EMAIL_USER'
}

export interface IUser {
  _id: string
  username: string
  verificationStatus: VerificationStatus
  onboardingCompleted: boolean
  profile: StudentProfile
  rewards: IReward[]
}

export interface AuthResponse {
  user: IUser
  token: string
}

export interface AuthCredentials {
  username: string
  password: string
}

export interface LoginCredentials extends AuthCredentials {
  keepSignedIn: boolean
}

export enum AchievementName {
  NEW_RECORD = 'New Record',
  VIP = 'VIP',
  PERFECT_WEEK = 'Perfect Week',
  PERFECT_MONTH = 'Perfect Month',
  TEAM_WORK_DELUXE = 'Team Work Deluxe',
  GOALS = '#Goals',
  GOALS_200 = '#Goals 200%',
  GOALS_300 = '#Goals 300%',
  GOALS_400 = '#Goals 400%',
  SIDE_QUEST = 'Side Quest',
  PAGEMASTER = 'Pagemaster',
  WORD_WIZARD = 'Word Wizard',
  SPEEDMASTER = 'Speedmaster',
  POWER_BUILDER = 'Power Builder'
}

export enum GoalNames {
  BooksFinished = 'BooksFinished',
  ExercisesFinished = 'ExercisesFinished',
  TimeSpent = 'TimeSpent'
}

export interface IReward {
  name: AchievementName
  score?: number
  goal?: GoalNames
  exerciseType?: ExerciseType
}

export interface ProfileCreateData {
  firstName: string
  lastName: string
  school: {
    name: string
  }
  country: string
  grade: string
}

export interface IProfileUpdate {
  firstName?: string
  lastName?: string
  globalScore?: number
  parent?: {
    name1?: string | null
    name2?: string | null
    email1?: string | null
    email2?: string | null
  }
  avatar?: null
}

// Actions:

export interface LoadUserAction {
  type: AUTH_ACTIONS.LOAD_USER
  user: IUser
}

export interface LoadUserProfileAction {
  type: AUTH_ACTIONS.LOAD_USER_PROFILE
  profile: StudentProfile
}

export type AuthActions = LoadUserAction | LoadUserProfileAction
