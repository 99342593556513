import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'

import { rootReducer } from './rootReducer'
import { initialRootState as rootState } from './rootReducer'
import { RootState } from './types'

const middlewareProd = compose(applyMiddleware(thunk))
export const middlewareDev = composeWithDevTools(applyMiddleware(reduxImmutableStateInvariant(), thunk))

const initStore = (initialRootState: RootState = rootState) =>
  createStore(rootReducer, initialRootState, process.env.NODE_ENV === 'production' ? middlewareProd : middlewareDev)

export default initStore
