import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useUpdateEffect(effect: () => void, dependencies: any[] = []) {
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
    // eslint-disable-next-line
  }, dependencies)
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}
