import { createSelector } from 'reselect'
import { Dispatch } from 'redux'

import ExerciseApi from './api'
import { RootState } from 'store/types'
import {
  IExerciseState,
  EXERCISE_ACTIONS,
  ExerciseActions,
  LoadExercisesAction,
  SetActiveExerciseAction,
  SetCurrentBookExercisesAmountAction,
  CleanExerciseStateAction
} from './types'

export const ExerciseInitialState: IExerciseState = {
  exercises: [],
  activeExerciseIndex: 0,
  currentBookExercisesAmount: 0
}

export const exerciseReducer = (state: IExerciseState = ExerciseInitialState, action: ExerciseActions) => {
  switch (action.type) {
    case EXERCISE_ACTIONS.LOAD_EXERCISES:
      return {
        ...state,
        exercises: action.exercises
      }
    case EXERCISE_ACTIONS.SET_ACTIVE_EXERCISE_INDEX:
      return {
        ...state,
        activeExerciseIndex: action.activeIndex
      }
    case EXERCISE_ACTIONS.SET_CURRENT_BOOK_EXERCISES_AMOUNT:
      return {
        ...state,
        currentBookExercisesAmount: action.amount
      }
    case EXERCISE_ACTIONS.CLEAN_STATE:
      return {
        ...ExerciseInitialState
      }
    default:
      return state
  }
}

// Action creators:

export const getBookExercises = (bookId: string) => async (dispatch: Dispatch<LoadExercisesAction>) => {
  try {
    const { data } = await ExerciseApi.getBookExercises(bookId)
    dispatch({ type: EXERCISE_ACTIONS.LOAD_EXERCISES, exercises: data })
  } catch (error) {
    console.warn(error)
  }
}

export const cleanExercisesState = (): CleanExerciseStateAction => ({
  type: EXERCISE_ACTIONS.CLEAN_STATE
})

export const setActiveExerciseIndex = (activeIndex: number): SetActiveExerciseAction => ({
  type: EXERCISE_ACTIONS.SET_ACTIVE_EXERCISE_INDEX,
  activeIndex
})

export const setCurrentBookExercisesAmount = (amount: number): SetCurrentBookExercisesAmountAction => ({
  type: EXERCISE_ACTIONS.SET_CURRENT_BOOK_EXERCISES_AMOUNT,
  amount
})

// Selectors:

export const selectActiveExerciseIndex = (state: RootState) => state.exercise.activeExerciseIndex

export const selectCurrentBookExerciseAmount = (state: RootState) => state.exercise.currentBookExercisesAmount

export const selectAllExercises = (state: RootState) => state.exercise.exercises

export const selectExerciseById = createSelector(
  selectAllExercises,
  (_: any, id?: string) => id,
  (exercises, id) => exercises.find((exercise) => exercise._id === id)
)
