import React, { useEffect } from 'react'
import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'

import { useQuery } from 'utils/hooks'
import { Typography, AppStoreQRCode, AppleStoreBadge, GooglePlayBadge } from '@astrid/components'
import MobileAppGraphic from '@astrid/components/dist/graphics/download_mobile_app.png'
import { ReactComponent as StylingElement } from 'resources/graphics/download_mobile_app_styling.svg'

import './MobileAppDownload.scss'

interface Translation {
  title: string
  mobileTitle: string
  text: string
  mobileText: string
}

const translations: { en: Translation; vi: Translation } = {
  en: {
    title: 'Astrid is an app for your phone or tablet!',
    mobileTitle: 'Download Astrid on your phone or tablet!',
    text:
      'If you do not have the app you can download it by scanning this QR code with your device’s camera or press the App Store or Google Play button below if you are on a mobile/tablet device:',
    mobileText: 'Press the App Store or Google Play button below to download Astrid'
  },
  vi: {
    title: 'Astrid là ứng dụng dành cho điện thoại và máy tính bảng!',
    mobileTitle: 'Astrid là ứng dụng tiếng Anh trên điện thoại và máy tính bảng!',
    text:
      'Nếu bạn chưa cài đặt Astrid thì hãy bắt đầu bằng cách dùng trình chụp ảnh trên thiệt bị di động để quét mả QR này hoặc nhấn vào biểu tượng App Store hoặc Google Play bên dưới nếu bạn đang dùng điện thoại hoặc máy tính bảng:',
    mobileText: ''
  }
}

const selectTranslation = (langCode: string) => {
  switch (langCode) {
    case 'vi':
      return translations.vi
    default:
      return translations.en
  }
}

const MobileAppDownload: React.FC = () => {
  const query = useQuery()
  const lang = query.get('lang') || 'en'

  const trans = selectTranslation(lang)

  useEffect(() => {
    const link_id = query.get('link_id')
    if (link_id) {
      axios.post(`http://e-eu.customeriomail.com/click/${link_id}`).catch((e) => {
        console.log(e)
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="mobile-app-download">
      <div className="mobile-app-download__container">
        <Typography variant="h1" className="mobile-app-download__title">
          {isMobile ? trans.mobileTitle : trans.title}
        </Typography>
        <div className="mobile-app-download__upper-container">
          <div className="mobile-app-download__qrcode">
            <AppStoreQRCode fill="#fff" />
          </div>
          <img src={MobileAppGraphic} alt="Google Play Badge" />
        </div>
        {((isMobile && trans.mobileText.length > 0) || trans.text.length > 0) && (
          <Typography variant="exerciseS" className="mobile-app-download__text">
            {isMobile ? trans.mobileText : trans.text}
          </Typography>
        )}
        <div className="mobile-app-download__store-buttons">
          {(!isMobile || (isMobile && isIOS)) && (
            <a href="https://apps.apple.com/us/app/astrid-learn-to-speak-english/id1528670445" target="__blank">
              <AppleStoreBadge className="mobile-app-download__badge" />
            </a>
          )}
          {(!isMobile || (isMobile && isAndroid)) && (
            <a href="https://play.google.com/store/apps/details?id=com.teach.withastrid" target="__blank">
              <GooglePlayBadge className="mobile-app-download__badge" />
            </a>
          )}
        </div>
        <StylingElement className="mobile-app-download__styling-element" />
      </div>
    </div>
  )
}

export default MobileAppDownload
