import * as React from 'react'
import { AstridLogo } from '@astrid/components'
import rightGraphic from '@astrid/components/dist/graphics/welcome-right.png'
import leftGraphic from '@astrid/components/dist/graphics/welcome-left.png'
import styles from './AuthWrapper.module.scss'

interface Props {
  noBackground?: boolean
}

const AuthWrapper: React.FC<Props> = ({ children, noBackground = false }) => (
  <div className={styles.root}>
    <div className={styles.container}>
      <AstridLogo className={styles.logo} />
      {children}
      {!noBackground && (
        <>
          <img className={styles.leftGraphic} src={leftGraphic} alt="characters-left" />
          <img className={styles.rightGraphic} src={rightGraphic} alt="characters-right" />
        </>
      )}
    </div>
  </div>
)

export default AuthWrapper
