import axios from 'axios'
import { cookiesToObject } from '../utils'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// If token exists add it to request headers
axiosInstance.interceptors.request.use((config) => {
  const cookiesObj = cookiesToObject()
  if (cookiesObj.token) {
    config.headers = { ...config.headers, Authorization: `${cookiesObj.token}` }
  }
  return config
})

export default axiosInstance
